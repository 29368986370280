import React from 'react';
import {useQuery, useMutation} from '@apollo/client';
import {useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import * as yup from 'yup';

import {makeStyles} from '@mui/styles';
import Button from '@mui/material/Button';

import {Spinner} from '../../../components/spinner';
import {FormAutocomplete} from '../../../components/autocomplete';
import {FormDatePicker} from '../../../components/date-picker';
import {FormTextField} from '../../../components/text-field';
import {internalFormatDate} from '../../../lib/helper';

import {GET_ALL_PUBLISHERS, ASSIGN_TERRITORY} from '../queries';

const useStyles = makeStyles(theme => ({
  formAction: {
    marginTop: theme.spacing(2),
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
}));

const schema = yup.object({
  publisher: yup.object({
    id: yup.string().required('Ein Verkündiger muss ausgewählt sein.'),
  }),
  createdAt: yup.date()
    .typeError('Kein gültiges Datum.')
    .min(new Date('1980-01-01'), 'Darf nicht zu weit in der Vergangenheit liegen.')
    .max(new Date(), 'Darf nicht in der Zukunft liegen.')
    .nullable(),
  notes: yup.string().nullable(),
}).required();

export const AssignForm = ({territory}) => {
  const classes = useStyles();

  const {loading: optionLoading, data: optionData} = useQuery(GET_ALL_PUBLISHERS);
  const [assignTerritory, {loading, error}] = useMutation(ASSIGN_TERRITORY, {
    // Not really advised, but as we have very little queries,
    // this is the savest way to keep everything in sync.
    refetchQueries: 'all',
  });

  const {
    control,
    handleSubmit,
    formState: {isValid},
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'onChange',
    defaultValues: {
      publisher: null,
      createdAt: null,
      notes: '',
    },
  });

  const onSubmit = ({publisher, createdAt, notes}) => {
    assignTerritory({
      variables: {
        territory_id: territory.id,
        publisher_id: publisher.id,
        created_at: internalFormatDate(createdAt),
        notes,
      },
    });
  };

  if (loading || !territory) {
    return <Spinner />;
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormAutocomplete
        margin="normal"
        fullWidth
        name="publisher"
        label="Verkündiger"
        control={control}
        loading={optionLoading}
        options={optionData?.publishersAll ?? []}
        getOptionLabel={option => option.name ?? ''}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        error={!!error}
        helperText={error ? error.message : 'Verkündiger, der das Gebiet bearbeiten möchte, auswählen.'}
      />

      <FormDatePicker
        disableFuture
        name="createdAt"
        label="Bearbeitung ab"
        helperText="Leer lassen um das aktuelle Datum zu nutzen."
        InputProps={{
          variant: 'outlined',
          margin: 'normal',
          fullWidth: true,
        }}
        control={control}
      />

      <FormTextField
        variant="outlined"
        margin="normal"
        fullWidth
        name="notes"
        label="Notizen"
        helperText="Optionale Bemerkung eingeben."
        control={control}
      />

      <div className={classes.formAction}>
        <Button
          variant="contained"
          color="primary"
          type="submit"
          disabled={!isValid}
        >
          Eintragen
        </Button>
      </div>
    </form>
  );
};

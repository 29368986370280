import React from 'react';

import {makeStyles} from '@mui/styles';
import MuiContainer from '@mui/material/Container';

const useStyles = makeStyles(theme => ({
  container: {
    padding: theme.spacing(1),
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(4),
    },
  },
}));

export const Container = props => {
  const classes = useStyles();
  return (
    <MuiContainer
      className={classes.container}
      {...props}
    />
  );
};

import React from 'react';

import {makeStyles} from '@mui/styles';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import Icon from '@mui/material/Icon';

const useStyles = makeStyles({
  search: {
    flexGrow: 1,
  },
});

export const Input = ({onChange, ...rest}) => {
  const classes = useStyles();

  return (
    <TextField
      variant="standard"
      className={classes.search}
      placeholder="Suche..."
      InputProps={{
        disableUnderline: true,
        startAdornment: (
          <InputAdornment position="start">
            <Icon>search</Icon>
          </InputAdornment>
        ),
      }}
      onChange={onChange}
      {...rest}
    />
  );
};

import {Login} from '../pages/auth';
import {Dashboard} from '../pages/dashboard';
import {Activities} from '../pages/log';
import {Users, Account} from '../pages/users';
import {Publishers, Profile} from '../pages/publishers';
import {
  Territories,
  Detail,
  TerritoryStock,
  UntreatedTerritories,
} from '../pages/territories';
import {
  Assignments,
  OverdueAssignments,
  ExportAssignmentRecord,
} from '../pages/assignments';

import {GuestRoute, PrivateRoute} from './route';

export const routes = [
  {
    name: 'Login',
    path: '/login',
    route: GuestRoute,
    component: Login,
  },
  {
    name: 'Aktivitäten',
    path: '/log',
    route: PrivateRoute,
    component: Activities,
  },
  {
    name: 'Passwort ändern',
    path: '/users/:id',
    route: PrivateRoute,
    component: Account,
  },
  {
    name: 'Benutzer',
    path: '/users',
    route: PrivateRoute,
    component: Users,
  },
  {
    name: 'Profil',
    path: '/publishers/:id',
    route: PrivateRoute,
    component: Profile,
  },
  {
    name: 'Verkündiger',
    path: '/publishers',
    route: PrivateRoute,
    component: Publishers,
  },
  {
    name: 'Gebietsbestand',
    path: '/territories/stock',
    route: PrivateRoute,
    component: TerritoryStock,
  },
  {
    name: 'überfällige Gebiete',
    path: '/territories/untreated',
    route: PrivateRoute,
    component: UntreatedTerritories,
  },
  {
    name: 'Übersicht',
    path: '/territories/:id',
    route: PrivateRoute,
    component: Detail,
  },
  {
    name: 'Gebiete',
    path: '/territories',
    route: PrivateRoute,
    component: Territories,
  },
  {
    name: 'überfällige Zuteilungen',
    path: '/assignments/overdue',
    route: PrivateRoute,
    component: OverdueAssignments,
  },
  {
    name: 'Zuteilungen exportieren',
    path: '/assignments/export',
    route: PrivateRoute,
    component: ExportAssignmentRecord,
  },
  {
    name: 'Zuteilungen',
    path: '/assignments',
    route: PrivateRoute,
    component: Assignments,
  },
  {
    name: 'Dashboard',
    path: '/',
    route: PrivateRoute,
    component: Dashboard,
  },
];

import React from 'react';

import {makeStyles} from '@mui/styles';
import Divider from '@mui/material/Divider';
import TabList from '@mui/lab/TabList';

const useStyles = makeStyles(theme => ({
  tabGroup: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
}));

export const TabBar = ({children, onChange, ...rest}) => {
  const classes = useStyles();

  const handleChange = (event, newValue) => {
    onChange(newValue);
  };

  return (
    <div className={classes.tabGroup}>
      <TabList onChange={handleChange} {...rest}>
        {children}
      </TabList>
      <Divider />
    </div>
  );
};

import React from 'react';

import {makeStyles} from '@mui/styles';
import TabContext from '@mui/lab/TabContext';
import TabPanel from '@mui/lab/TabPanel';
import Tab from '@mui/material/Tab';

import {TabBar} from './tabbar';

const useStyles = makeStyles({
  tabPanel: {
    padding: 0,
  },
});

// convenience export
export {
  TabContext,
  Tab,
};

export const TabContent = ({children, ...rest}) => {
  const classes = useStyles();

  return (
    <TabPanel
      className={classes.tabPanel}
      {...rest}
    >
      {children}
    </TabPanel>
  );
};

export const TabPanels = ({children}) => (
  React.Children.map(children, (child, i) => (
    React.cloneElement(child, {value: `${i}`})
  ))
);

export const Tabs = ({onChange, children}) => {
  const tabs = React.Children.map(children, (child, i) => (
    React.cloneElement(child, {value: `${i}`})
  ));

  return (
    <TabBar onChange={onChange}>
      {tabs}
    </TabBar>
  );
};

import React from 'react';
import {useHistory} from 'react-router-dom';
import {useQuery} from '@apollo/client';

import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';

import {Layout} from '../../components/layout';
import {Container} from '../../components/container';
import {Toolbar} from '../../components/toolbar';
import {DataTable} from '../../components/table';
import {formatDate} from '../../lib/helper';

import {GET_TERRITORIES_IN_STOCK} from './queries';

export const TerritoryStock = () => {
  const history = useHistory();

  const {loading, error, data} = useQuery(GET_TERRITORIES_IN_STOCK);

  return (
    <Layout>
      <Container maxWidth="lg">
        <Toolbar />
        <Grid
          container
          justifyContent="center"
          spacing={3}
        >
          <Grid item xs={12}>
            <DataTable
              component={Paper}
              loading={loading}
              error={error}
              columns={[
                {key: 'key', label: 'Gebiet'},
                {key: 'created_at', label: 'Zuletzt bearbeitet am'},
              ]}
              renderItem={item => (
                <TableRow
                  hover
                  onClick={() => history.push(`/territories/${item.id}`)}
                  key={item.id}
                >
                  <TableCell>
                    {item.key}
                    <Typography
                      variant="body2"
                      color="textSecondary"
                    >
                      {item.name}
                    </Typography>
                  </TableCell>
                  <TableCell>{formatDate(item.assignments[0].finished_at)}</TableCell>
                </TableRow>
              )}
              items={data?.territoriesInStock}
            />
          </Grid>
        </Grid>
      </Container>
    </Layout>
  );
};

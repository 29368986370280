import {
  ApolloClient,
  HttpLink,
  ApolloLink,
  concat,
} from '@apollo/client';
import {cache} from './cache';

let apolloClient = null;

function createLink() {
  // eslint-disable-next-line no-underscore-dangle
  const uri = (window.__RUNTIME_CONFIG__?.REACT_APP_GRAPH_URL || '/graphql');

  const httpLink = new HttpLink({
    uri,
  });

  const authMiddleware = new ApolloLink((operation, forward) => {
    // get the authentication token from local storage if it exists
    const token = localStorage.getItem('token');
    // add the authorization to the headers
    operation.setContext({
      headers: {
        authorization: token ? `Bearer ${token}` : null,
      },
    });

    return forward(operation);
  });

  return concat(authMiddleware, httpLink);
}

async function createClient() {
  // create a network link with a authorization resolver
  const link = createLink();

  const client = new ApolloClient({
    connectToDevTools: process.env.NODE_ENV !== 'production',
    link,
    cache,
  });

  return client;
}

export default async function initApollo() {
  // Reuse client on the client-side
  if (!apolloClient) {
    apolloClient = await createClient();
  }

  return apolloClient;
}

import {gql} from '@apollo/client';

export const GET_USERS = gql`
  query GetUsers($first: Int!, $page: Int!) {
    users(first: $first, page: $page) {
      paginatorInfo {
        total
        count
        currentPage
      }
      data {
        id
        name
        email
        created_at
        updated_at
      }
    }
  }
`;

import locale from 'date-fns/locale/de';
import isDate from 'date-fns/isDate';
import format from 'date-fns/format';
import formatDistance from 'date-fns/formatDistance';
import parseISO from 'date-fns/parseISO';

/**
 * Ensure that given date is a correct date object.
 *
 * @param {Date|string} date
 */
export const parseDate = date => (
  !isDate(date) ? parseISO(date) : date
);

/**
 * Format date to display human readable absolute date
 *
 * @param {Date|string} date
 * @param {string} fmt
 */
export const formatDate = (date, fmt = 'P') => (
  date && format(parseDate(date), fmt, {
    locale,
  })
);

/**
 * Format date to display human readable difference (relative date)
 *
 * @param {Date|string} date
 * @param {Date|string} to
 */
export const formatDateDistance = (date, to = null) => (
  date && formatDistance(parseDate(date), to ? parseDate(to) : new Date(), {
    addSuffix: !to,
    locale,
  })
);

/**
 * Correct format to transmit date to api.
 *
 * @param {Date} date
 */
export const internalFormatDate = date => (
  date && format(date, 'yyyy-MM-dd HH:mm:ss')
);

import React, {useEffect, useState} from 'react';
import {ApolloProvider} from '@apollo/client';

import {
  ThemeProvider,
  StyledEngineProvider,
} from '@mui/material/styles';
import {createStyles, makeStyles} from '@mui/styles';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';
import CssBaseline from '@mui/material/CssBaseline';
// import useMediaQuery from '@mui/material/useMediaQuery';
import CircularProgress from '@mui/material/CircularProgress';
import locale from 'date-fns/locale/de';

import initApollo from './lib/init-apollo';
import {Router} from './navigation';

import createTheme from './theme';

const useStyles = makeStyles(() => createStyles({
  '@global': {
    '*': {
      boxSizing: 'border-box',
      margin: 0,
      padding: 0,
    },
    html: {
      '-webkit-font-smoothing': 'antialiased',
      '-moz-osx-font-smoothing': 'grayscale',
      height: '100%',
      width: '100%',
    },
    body: {
      height: '100%',
      width: '100%',
    },
    '#root': {
      height: '100%',
      width: '100%',
    },
    '#loading': {
      display: 'flex',
      minHeight: '100vh',
      justifyContent: 'center',
      alignItems: 'center',
    },
  },
}));

export const App = () => {
  useStyles();
  // const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  const [client, setClient] = useState(null);

  useEffect(() => {
    initApollo().then(setClient);
  }, []);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={createTheme('dark')}>
        {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
        <CssBaseline />
        {client ? (
          <ApolloProvider client={client}>
            <LocalizationProvider
              dateAdapter={AdapterDateFns}
              adapterLocale={locale}
            >
              <Router />
            </LocalizationProvider>
          </ApolloProvider>
        ) : (
          <main id="loading">
            {/* Show a loading spinner while the app is booting */}
            <CircularProgress />
          </main>
        )}
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

import {gql} from '@apollo/client';

export const GET_PUBLISHERS = gql`
  query GetPublishers($first: Int!, $page: Int!) {
    publishers(
      first: $first,
      page: $page,
      trashed: WITH
    ) {
      paginatorInfo {
        total
        count
        currentPage
      }
      data {
        id
        name
        is_suspended
        assignments {
          id
        }
        created_at
        updated_at
        suspended_at
      }
    }
  }
`;

import React from 'react';
import {Controller} from 'react-hook-form';

import MuiAutocomplete from '@mui/material/Autocomplete';
import MuiTextField from '@mui/material/TextField';

export const FormAutocomplete = ({
  name,
  control,
  helperText,
  ...inputProps
}) => (
  <Controller
    name={name}
    control={control}
    render={({field: {ref, ...field}, fieldState: {error}}) => (
      <Autocomplete
        {...field}
        {...inputProps}
        error={!!error}
        helperText={error?.message ?? helperText}
      />
    )}
  />
);

export const Autocomplete = ({
  loading,
  options,
  getOptionLabel,
  isOptionEqualToValue,
  value,
  onChange,
  multiple,
  ...rest
}) => (
  <MuiAutocomplete
    autoHighlight
    disableClearable
    multiple={multiple}
    loading={loading}
    options={options}
    getOptionLabel={getOptionLabel}
    isOptionEqualToValue={isOptionEqualToValue}
    value={value}
    onChange={(event, newValue) => onChange?.(newValue)}
    renderInput={params => (
      <MuiTextField
        {...params}
        {...rest}
      />
    )}
  />
);

import React, {useState} from 'react';
import {useParams} from 'react-router-dom';
import {useQuery} from '@apollo/client';

import {Layout} from '../../components/layout';
import {Container} from '../../components/container';
import {Toolbar} from '../../components/toolbar';
import {Spinner} from '../../components/spinner';
import {Error} from '../../components/error';
import {
  TabContext,
  TabContent,
  TabPanels,
  Tabs,
  Tab,
} from '../../components/tabs';

import {NotFound} from '../error';

import {GET_TERRITORY} from './queries';
import {AssignTab, PublishersTab} from './tabs';

export const Detail = () => {
  const [tab, setTab] = useState('0');
  const {id} = useParams();

  const {loading, error, data} = useQuery(GET_TERRITORY, {
    variables: {id},
  });

  if (data && !data.territory) {
    return (
      <NotFound
        message="Das Gebiet wurde nicht gefunden"
        to="/territories"
      />
    );
  }

  return (
    <Layout>
      <Container maxWidth="lg">
        <Toolbar title={data?.territory?.key} />

        {loading && (
          <Spinner />
        )}

        {error && (
          <Error error={error} />
        )}

        {data?.territory && (
          <TabContext value={tab}>
            <Tabs onChange={setTab}>
              <Tab label="Zuteilung" />
              <Tab label="Verkündiger" />
            </Tabs>

            <TabPanels>
              <TabContent>
                <AssignTab data={data} />
              </TabContent>
              <TabContent>
                <PublishersTab data={data} />
              </TabContent>
            </TabPanels>
          </TabContext>
        )}
      </Container>
    </Layout>
  );
};

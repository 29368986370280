import {gql} from '@apollo/client';

export const GET_USER = gql`
  query GetUser($id: ID!) {
    user(id: $id) {
      email
      firstname
      lastname
    }
  }
`;

export const UPDATE_USER = gql`
  mutation ChangeUserPassword(
    $id: ID!,
    $email: String!,
    $firstname: String!,
    $lastname: String!
  ) {
    updateUser(
      id: $id,
      input: {
        email: $email
        firstname: $firstname
        lastname: $lastname
      }
    ) {
      id
    }
  }
`;

export const CHANGE_USER_PASSWORD = gql`
  mutation ChangeUserPassword(
    $id: ID!,
    $password: String!,
    $password_confirmation: String!
  ) {
    changeUserPassword(
      id: $id,
      input: {
        password: $password
        password_confirmation: $password_confirmation
      }
    ) {
      id
    }
  }
`;

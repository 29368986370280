import React from 'react';
import {BrowserRouter, Switch, Route} from 'react-router-dom';

import {NotFound} from '../pages/error';

import {BreadcrumbContext} from './breadcrumbs';
import {routes} from './routes';

export const Router = () => (
  <BrowserRouter>
    <Switch>
      {routes.map(route => (
        <route.route
          exact
          key={route.path}
          path={route.path}
          routes={routes}
        >
          {breadcrumbs => (
            <BreadcrumbContext.Provider value={breadcrumbs}>
              <route.component />
            </BreadcrumbContext.Provider>
          )}
        </route.route>
      ))}
      <Route component={NotFound} />
    </Switch>
  </BrowserRouter>
);

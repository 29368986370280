import {useState, useEffect} from 'react';

export const useCurrentAssignment = territory => {
  const [assignment, setAssignment] = useState(null);

  useEffect(() => {
    const data = territory.assignments.find(x => x.finished_at === null);
    setAssignment(data);
  }, [territory]);

  return assignment;
};

import React, {useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {useQuery} from '@apollo/client';

import {makeStyles} from '@mui/styles';
import Icon from '@mui/material/Icon';
import Typography from '@mui/material/Typography';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';

import {formatDate} from '../../lib/helper';

import {DataTable} from '../table';

import {GET_TERRITORIES} from './queries';

const useStyles = makeStyles({
  icon: {
    fontSize: 15,
  },
});

export const TerritoryTable = ({component, count}) => {
  const history = useHistory();
  const classes = useStyles();
  const [rowsPerPage, setRowsPerPage] = useState(count || 15);
  const [currentPage, setCurrentPage] = useState(1);

  const {
    loading,
    error,
    data,
    fetchMore,
  } = useQuery(GET_TERRITORIES, {
    variables: {first: rowsPerPage, page: currentPage},
  });

  useEffect(() => {
    fetchMore({
      variables: {first: rowsPerPage, page: currentPage},
    });
  }, [rowsPerPage, currentPage]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <DataTable
      component={component}
      loading={loading}
      error={error}
      columns={[
        {key: 'is_available', label: 'Verfügbar'},
        {key: 'key', label: 'Gebiet'},
        {key: 'created_at', label: 'Erstellt am'},
        {key: 'updated_at', label: 'Aktualisiert am'},
      ]}
      renderItem={item => (
        <TableRow
          hover
          onClick={() => history.push(`/territories/${item.id}`)}
          key={item.id}
        >
          <TableCell
            align="center"
            padding="checkbox"
          >
            {item.is_available ? (
              <Icon className={classes.icon}>check</Icon>
            ) : (
              <Icon className={classes.icon}>block</Icon>
            )}
          </TableCell>
          <TableCell>
            {item.key}
            <Typography
              variant="body2"
              color="textSecondary"
            >
              {item.name}
            </Typography>
          </TableCell>
          <TableCell>{formatDate(item.created_at)}</TableCell>
          <TableCell>{formatDate(item.updated_at)}</TableCell>
        </TableRow>
      )}
      items={data?.territories.data}
      pagination={data?.territories.paginatorInfo}
      rowsPerPage={rowsPerPage}
      onPageChange={setCurrentPage}
      onRowsPerPageChange={setRowsPerPage}
    />
  );
};

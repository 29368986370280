import React from 'react';

import {makeStyles} from '@mui/styles';
import Grid from '@mui/material/Unstable_Grid2';
import Paper from '@mui/material/Paper';

import {Layout} from '../../components/layout';
import {Container} from '../../components/container';
import {Toolbar} from '../../components/toolbar';

import {Duration} from './modules/duration';
import {Territories} from './modules/territories';
import {Transactions} from './modules/transactions';

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
}));

export const Dashboard = () => {
  const classes = useStyles();
  return (
    <Layout>
      <Container maxWidth="lg">
        <Toolbar />
        <Grid container spacing={3}>
          <Grid item xs={12} md={8} lg={9}>
            <Paper className={classes.paper}>
              <Duration />
            </Paper>
          </Grid>

          <Grid item xs={12} md={4} lg={3}>
            <Paper className={classes.paper}>
              <Territories />
            </Paper>
          </Grid>

          <Grid item xs={12}>
            <Paper className={classes.paper}>
              <Transactions />
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </Layout>
  );
};

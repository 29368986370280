import React from 'react';

import {makeStyles} from '@mui/styles';
import Toolbar from '@mui/material/Toolbar';
import MuiDrawer from '@mui/material/Drawer';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Icon from '@mui/material/Icon';

import {Error} from '../error';
import {Spinner} from '../spinner';

import {Results} from './results';

const useStyles = makeStyles(theme => ({
  drawer: {
    zIndex: theme.zIndex.drawer + 2,
  },
  toolbar: {
    marginLeft: 72,
  },
  toolbarIndent: {
    marginLeft: 240,
  },
}));

export const Container = ({
  children,
  indent,
  loading,
  error,
  data,
  open,
  onClose,
  onItemClick,
}) => {
  const classes = useStyles();

  const handleItemClick = item => {
    // Close the container.
    onClose();

    // Send click event.
    onItemClick(item);
  };

  return (
    <MuiDrawer
      anchor="top"
      open={open}
      onClose={onClose}
      classes={{
        root: classes.drawer,
      }}
    >
      <Toolbar className={indent ? classes.toolbarIndent : classes.toolbar}>
        {children}
        <IconButton tabIndex={-1} onClick={onClose}>
          <Icon>close</Icon>
        </IconButton>
      </Toolbar>
      <Divider />
      {error && (
        <Error dense error={error} />
      )}
      {loading && (
        <Spinner />
      )}
      {data && (
        <Results
          items={data}
          onItemClick={handleItemClick}
        />
      )}
    </MuiDrawer>
  );
};

import React from 'react';
import {Link as RouterLink} from 'react-router-dom';
import {
  ResponsiveContainer,
  BarChart,
  Bar,
  CartesianGrid,
  Legend,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import {gql, useQuery} from '@apollo/client';

import {useTheme, makeStyles} from '@mui/styles';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';

import {Error} from '../../../components/error';
import {Spinner} from '../../../components/spinner';

import {Title} from './title';

const GET_STATISTIC_TERRITORY_TRANSACTIONS = gql`
  query GetStatisticTerritoryTransactions {
    statisticTerritoryTransaction {
      incoming
      outgoing
      date
    }
  }
`;

const useStyles = makeStyles({
  titlebar: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  title: {
    flexGrow: 1,
  },
});

export const Transactions = () => {
  const theme = useTheme();
  const classes = useStyles();
  const {loading, error, data} = useQuery(GET_STATISTIC_TERRITORY_TRANSACTIONS);

  if (error) {
    return <Error dense error={error} />;
  }

  if (loading) {
    return <Spinner />;
  }

  return (
    <div>
      <Box className={classes.titlebar}>
        <Title className={classes.title}>Transaktionen</Title>
        <Link component={RouterLink} to="/assignments">
          Alle anzeigen
        </Link>
      </Box>

      <div style={{width: '100%', height: 400, overflow: 'hidden'}}>
        <ResponsiveContainer>
          <BarChart data={data?.statisticTerritoryTransaction}>
            <CartesianGrid
              vertical={false}
              strokeDasharray="3 3"
              stroke={theme.palette.text.secondary}
            />
            <XAxis
              dataKey="date"
              height={50}
              tickSize={20}
              tickLine={false}
              axisLine={false}
              stroke={theme.palette.text.secondary}
            />
            <YAxis
              unit="stk"
              tickSize={20}
              tickLine={false}
              axisLine={false}
              stroke={theme.palette.text.secondary}
            />
            <Tooltip
              cursor={false}
              contentStyle={{
                borderRadius: '4px',
                borderColor: theme.palette.divider,
                background: theme.palette.background.default,
              }}
            />
            <Legend />
            <Bar
              dataKey="incoming"
              name="Als bearbeitet gemeldet"
              fill={theme.palette.primary.main}
            />
            <Bar
              dataKey="outgoing"
              name="Ausgegeben"
              fill={theme.palette.error.main}
            />
          </BarChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

import React, {useState, useEffect} from 'react';
import {useQuery, useMutation} from '@apollo/client';
import {useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import * as yup from 'yup';

import {makeStyles} from '@mui/styles';
import Dialog from '@mui/material/Dialog';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import {Layout} from '../../components/layout';
import {Container} from '../../components/container';
import {Toolbar} from '../../components/toolbar';
import {Spinner} from '../../components/spinner';
import {FormAutocomplete} from '../../components/autocomplete';
import {FormSelect} from '../../components/select';
import {FormDatePicker} from '../../components/date-picker';
import {internalFormatDate} from '../../lib/helper';

import {GET_ALL_TERRITORIES, EXPORT_ASSIGNMENT_RECORD} from './queries';

const useStyles = makeStyles(theme => ({
  formGroup: {
    marginBottom: theme.spacing(4),
    padding: theme.spacing(4),
  },
  formAction: {
    marginTop: theme.spacing(2),
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
}));

const dateSchema = yup.date()
  .typeError('Kein gültiges Datum.')
  .min(new Date('1980-01-01'), 'Darf nicht zu weit in der Vergangenheit liegen.')
  .max(new Date(), 'Darf nicht in der Zukunft liegen.')
  .nullable();

const schema = yup.object({
  territories: yup.array(),
  column: yup.string().oneOf(['KEY', 'CREATED_AT', 'UPDATED_AT']),
  order: yup.string().oneOf(['ASC', 'DESC']),
  from: dateSchema,
  to: dateSchema,
}).required();

export const ExportAssignmentRecord = () => {
  const classes = useStyles();
  const {loading: optionLoading, data: optionData} = useQuery(GET_ALL_TERRITORIES);
  const [exportRecord, {loading, error, data}] = useMutation(EXPORT_ASSIGNMENT_RECORD);
  const [showModal, setShowModal] = useState(false);

  const {
    control,
    handleSubmit,
    formState: {isValid},
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'onChange',
    defaultValues: {
      territories: [],
      column: 'KEY',
      order: 'ASC',
      from: null,
      to: null,
    },
  });

  useEffect(() => {
    setShowModal(!!data);
  }, [data]);

  const onSubmit = ({
    territories, column, order, from, to,
  }) => {
    exportRecord({
      variables: {
        territories: territories.map(x => x.key),
        orderBy: [{column, order}],
        from: internalFormatDate(from),
        to: internalFormatDate(to),
      },
    });
  };

  return (
    <Layout>
      <Container maxWidth="lg">
        <Toolbar />
        <Grid
          container
          justifyContent="center"
          spacing={3}
        >
          <Grid item xs={12} md={6}>
            <Paper className={classes.formGroup}>
              <form onSubmit={handleSubmit(onSubmit)}>
                <FormAutocomplete
                  margin="normal"
                  autoFocus
                  fullWidth
                  multiple
                  name="territories"
                  label="Gebiete"
                  control={control}
                  loading={optionLoading}
                  options={optionData?.territoriesAll ?? []}
                  getOptionLabel={option => option.key}
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  error={!!error}
                  helperText={error ? error.message : 'Leer lassen um alle Gebiete zu exportieren.'}
                />

                <Grid container spacing={1}>
                  <Grid item xs={6}>
                    <FormSelect
                      margin="normal"
                      fullWidth
                      name="column"
                      label="Sortiere nach"
                      control={control}
                      options={[
                        {value: 'KEY', label: 'Gebiet'},
                        {value: 'CREATED_AT', label: 'Erstellt am'},
                        {value: 'UPDATED_AT', label: 'Aktualisiert am'},
                      ]}
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <FormSelect
                      margin="normal"
                      fullWidth
                      name="order"
                      label="Reihenfolge"
                      control={control}
                      options={[
                        {value: 'ASC', label: 'Aufsteigend'},
                        {value: 'DESC', label: 'Absteigend'},
                      ]}
                    />
                  </Grid>
                </Grid>

                <FormDatePicker
                  disableFuture
                  name="from"
                  label="Zeitraum ab"
                  helperText="Leer lassen um den gesamten Zeitraum zu exportieren."
                  InputProps={{
                    variant: 'outlined',
                    margin: 'normal',
                    fullWidth: true,
                  }}
                  control={control}
                />

                <FormDatePicker
                  disableFuture
                  name="to"
                  label="Zeitraum bis"
                  helperText="Leer lassen um den gesamten Zeitraum zu exportieren."
                  InputProps={{
                    variant: 'outlined',
                    margin: 'normal',
                    fullWidth: true,
                  }}
                  control={control}
                />

                <div className={classes.formAction}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={!isValid}
                  >
                    Exportieren
                  </Button>
                </div>
              </form>
            </Paper>

            {loading && (
              <Spinner />
            )}

            <Dialog
              open={showModal}
              onClose={() => setShowModal(false)}
            >
              <div className={classes.formGroup}>
                <Typography variante="body1">
                  Export abgeschlossen. Klicke auf &quot;Anzeigen&quot;
                  um die Datei anzusehen oder herunterzuladen.
                </Typography>
                <div className={classes.formAction}>
                  <Button
                    type="button"
                    variant="contained"
                    component="a"
                    target="_blank"
                    href={data?.exportAssignmentRecord}
                  >
                    Anzeigen
                  </Button>
                </div>
              </div>
            </Dialog>

          </Grid>
        </Grid>
      </Container>
    </Layout>
  );
};

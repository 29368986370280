import React from 'react';
import {useParams} from 'react-router-dom';
import {useQuery, useMutation} from '@apollo/client';
import {useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import * as yup from 'yup';

import {makeStyles} from '@mui/styles';
import Grid from '@mui/material/Unstable_Grid2';
import Paper from '@mui/material/Paper';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';

import {Layout} from '../../components/layout';
import {Container} from '../../components/container';
import {Toolbar} from '../../components/toolbar';
import {Error} from '../../components/error';
import {Spinner} from '../../components/spinner';
import {FormTextField} from '../../components/text-field';

import {NotFound} from '../error';

import {GET_USER, CHANGE_USER_PASSWORD} from './queries';

const useStyles = makeStyles(theme => ({
  formGroup: {
    marginBottom: theme.spacing(4),
    padding: theme.spacing(4),
  },
  formAction: {
    marginTop: theme.spacing(2),
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
}));

const schema = yup.object({
  password: yup.string()
    .required('Passwort muss ausgefüllt sein.'),
  passwordConfirm: yup.string()
    .oneOf([yup.ref('password'), null], 'Passwörter müssen übereinstimmen.'),
}).required();

export const Account = () => {
  const classes = useStyles();
  const {id} = useParams();

  const {error: queryError, loading: queryLoading, data} = useQuery(GET_USER, {
    variables: {id},
  });

  const [changeUserPassword, {
    error: mutationError,
    loading: mutationLoading,
    data: mutationSuccess,
  }] = useMutation(CHANGE_USER_PASSWORD);

  const {
    control,
    handleSubmit,
    formState: {isValid},
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'onChange',
    defaultValues: {
      password: '',
      passwordConfirm: '',
    },
  });

  const onSubmit = ({password, passwordConfirm}) => {
    changeUserPassword({
      variables: {
        id,
        password,
        password_confirmation: passwordConfirm,
      },
    });
  };

  if (data && !data.user) {
    return (
      <NotFound
        message="Der Benutzer wurde nicht gefunden"
        to="/users"
      />
    );
  }

  return (
    <Layout>
      <Container maxWidth="lg">
        <Toolbar />
        <Grid
          container
          justifyContent="center"
          spacing={3}
        >
          {(mutationLoading || queryLoading) && (
            <Grid item xs={12}>
              <Spinner />
            </Grid>
          )}

          {mutationSuccess && (
            <Grid item xs={12}>
              <Alert severity="success" variant="filled">Neues Passwort gespeichert.</Alert>
            </Grid>
          )}

          {queryError && (
            <Grid item xs={12}>
              <Error error={queryError} />
            </Grid>
          )}

          {data?.user && (
            <>
              <Grid item xs={12} md={6}>
                <Paper className={classes.formGroup}>
                  <form>
                    <TextField
                      label="Vorname"
                      margin="normal"
                      fullWidth
                      disabled
                      value={data.user.firstname}
                    />

                    <TextField
                      label="Nachname"
                      margin="normal"
                      fullWidth
                      disabled
                      value={data.user.lastname}
                    />

                    <TextField
                      label="E-Mail"
                      margin="normal"
                      fullWidth
                      disabled
                      value={data.user.email}
                    />
                  </form>
                </Paper>
              </Grid>
              <Grid item xs={12} md={6}>
                <Paper className={classes.formGroup}>
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <FormTextField
                      margin="normal"
                      type="password"
                      fullWidth
                      name="password"
                      label="Neues Passwort"
                      error={!!mutationError}
                      helperText={mutationError?.message}
                      control={control}
                    />

                    <FormTextField
                      margin="normal"
                      type="password"
                      fullWidth
                      name="passwordConfirm"
                      label="Neues Passwort bestätigen"
                      helperText="Passwort nocheinmal eingeben um es zu bestätigen."
                      control={control}
                    />

                    <div className={classes.formAction}>
                      <Button
                        variant="contained"
                        color="primary"
                        type="submit"
                        disabled={!isValid}
                      >
                        Absenden
                      </Button>
                    </div>
                  </form>
                </Paper>
              </Grid>
            </>
          )}
        </Grid>
      </Container>
    </Layout>
  );
};

import React from 'react';
import {useHistory} from 'react-router-dom';
import {useQuery} from '@apollo/client';

import {makeStyles} from '@mui/styles';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';

import {Layout} from '../../components/layout';
import {Container} from '../../components/container';
import {Toolbar} from '../../components/toolbar';
import {DataTable} from '../../components/table';
import {formatDate} from '../../lib/helper';

import {GET_OVERDUE_ASSIGNMENTS} from './queries';

const useStyles = makeStyles(theme => ({
  crossedText: {
    color: theme.palette.text.disabled,
    textDecoration: 'line-through',
  },
}));

export const OverdueAssignments = () => {
  const history = useHistory();
  const classes = useStyles();

  const {loading, error, data} = useQuery(GET_OVERDUE_ASSIGNMENTS, {
    variables: {months: 12},
  });

  return (
    <Layout>
      <Container maxWidth="lg">
        <Toolbar />
        <Grid
          container
          justifyContent="center"
          spacing={3}
        >
          <Grid item xs={12}>
            <DataTable
              component={Paper}
              loading={loading}
              error={error}
              columns={[
                {key: 'publisher.name', label: 'Verkündiger'},
                {key: 'territory.key', label: 'Gebiets-Nr.'},
                {key: 'notes', label: 'Notiz'},
                {key: 'created_at', label: 'Ausgegeben am'},
              ]}
              renderItem={item => (
                <TableRow
                  hover
                  onClick={() => history.push(`/publishers/${item.publisher.id}`)}
                  key={item.id}
                >
                  <TableCell className={item.publisher.is_suspended ? classes.crossedText : null}>
                    {item.publisher.name}
                  </TableCell>
                  <TableCell>
                    {item.territory.key}
                    <Typography
                      variant="body2"
                      color="textSecondary"
                    >
                      {item.territory.name}
                    </Typography>
                  </TableCell>
                  <TableCell>{item.notes}</TableCell>
                  <TableCell>{formatDate(item.created_at)}</TableCell>
                </TableRow>
              )}
              items={data?.assignmentsOverdue}
            />
          </Grid>
        </Grid>
      </Container>
    </Layout>
  );
};

export const components = {
  MuiLinearProgress: {
    styleOverrides: {
      root: {
        borderRadius: 3,
        overflow: 'hidden',
      },
    },
  },
  MuiLink: {
    defaultProps: {
      underline: 'hover',
    },
  },
  MuiPaper: {
    styleOverrides: {
      root: {
        backgroundImage: 'unset',
      },
    },
  },
  /* makes text hug the icon on the right side */
  // MuiListItemIcon: {
  //   styleOverrides: {
  //     root: {
  //       minWidth: 'auto',
  //       marginRight: '16px',
  //     },
  //   },
  // },
  MuiButton: {
    styleOverrides: {
      root: {
        textTransform: 'none',
      },
    },
  },
  MuiChip: {
    styleOverrides: {
      root: {
        backgroundColor: 'rgba(0, 0, 0, 0.075)',
      },
    },
  },
  MuiTableCell: {
    styleOverrides: {
      root: {
        borderBottom: '1px solid rgba(145, 158, 171, 0.24)',
      },
    },
  },
};

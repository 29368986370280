import React from 'react';
import {useMutation} from '@apollo/client';
import {useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import * as yup from 'yup';

import {makeStyles} from '@mui/styles';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';

import {Spinner} from '../../../components/spinner';
import {FormDatePicker} from '../../../components/date-picker';
import {useCurrentAssignment} from '../../../lib/hooks';
import {
  formatDate,
  formatDateDistance,
  internalFormatDate,
} from '../../../lib/helper';

import {UNASSIGN_TERRITORY} from '../queries';

const useStyles = makeStyles(theme => ({
  formAction: {
    marginTop: theme.spacing(2),
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
}));

const schema = yup.object({
  finishedAt: yup.date()
    .typeError('Kein gültiges Datum.')
    .min(new Date('1980-01-01'), 'Darf nicht zu weit in der Vergangenheit liegen.')
    .max(new Date(), 'Darf nicht in der Zukunft liegen.')
    .nullable(),
}).required();

export const UnassignForm = ({territory}) => {
  const classes = useStyles();
  const assignment = useCurrentAssignment(territory);

  const [unassignTerritory, {loading, error}] = useMutation(UNASSIGN_TERRITORY, {
    // Not really advised, but as we have very little queries,
    // this is the savest way to keep everything in sync.
    refetchQueries: 'all',
  });

  const {
    control,
    handleSubmit,
    formState: {isValid},
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'onChange',
    defaultValues: {
      finishedAt: null,
    },
  });

  const onSubmit = ({finishedAt}) => {
    unassignTerritory({
      variables: {
        territory_id: territory.id,
        finished_at: internalFormatDate(finishedAt),
      },
    });
  };

  if (loading || !assignment) {
    return <Spinner />;
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <TextField
        margin="normal"
        fullWidth
        disabled
        value={assignment.publisher.name}
      />

      <TextField
        margin="normal"
        fullWidth
        disabled
        value={formatDate(assignment.created_at)}
        helperText={formatDateDistance(assignment.created_at)}
      />

      <FormDatePicker
        disableFuture
        name="finishedAt"
        label="Zurück am"
        InputProps={{
          variant: 'outlined',
          margin: 'normal',
          fullWidth: true,
        }}
        control={control}
        error={!!error}
        helperText={error ? error.message : 'Leer lassen um das aktuelle Datum zu nutzen.'}
      />

      <div className={classes.formAction}>
        <Button
          variant="contained"
          color="primary"
          type="submit"
          disabled={!isValid}
        >
          Austragen
        </Button>
      </div>
    </form>
  );
};

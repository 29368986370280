import React from 'react';
import clsx from 'clsx';

import {makeStyles} from '@mui/styles';
import Hidden from '@mui/material/Hidden';
import MuiDrawer from '@mui/material/Drawer';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Icon from '@mui/material/Icon';

const useStyles = makeStyles(theme => ({
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  drawer: {
    width: 240,
    height: '100vh',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
  },
  drawerPaper: {
    overflowX: 'hidden',
    position: 'relative',
    whiteSpace: 'nowrap',
  },
}));

export const Drawer = props => (
  <>
    <Hidden smUp>
      <TemporaryDrawer {...props} />
    </Hidden>
    <Hidden smDown>
      <PermantentDrawer {...props} />
    </Hidden>
  </>
);

const TemporaryDrawer = ({
  drawerOpen,
  onDrawerCloseClick,
  children,
}) => {
  const classes = useStyles();

  return (
    <MuiDrawer
      variant="temporary"
      classes={{
        root: classes.drawer,
        paper: classes.drawerPaper,
      }}
      ModalProps={{
        keepMounted: true, // Better open performance on mobile.
      }}
      open={drawerOpen}
      onClose={onDrawerCloseClick}
    >
      <div className={classes.toolbarIcon}>
        <IconButton onClick={onDrawerCloseClick} size="large">
          <Icon>chevron_left</Icon>
        </IconButton>
      </div>
      <Divider />
      {children}
    </MuiDrawer>
  );
};

const PermantentDrawer = ({
  drawerOpen,
  onDrawerCloseClick,
  children,
}) => {
  const classes = useStyles();

  return (
    <MuiDrawer
      variant="permanent"
      classes={{
        root: clsx(classes.drawer, !drawerOpen && classes.drawerClose),
        paper: classes.drawerPaper,
      }}
      open={drawerOpen}
      onClose={onDrawerCloseClick}
    >
      <div className={classes.toolbarIcon}>
        <IconButton onClick={onDrawerCloseClick} size="large">
          <Icon>chevron_left</Icon>
        </IconButton>
      </div>
      <Divider />
      {children}
    </MuiDrawer>
  );
};

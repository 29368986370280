import React, {useEffect} from 'react';
import {useMutation} from '@apollo/client';
import {useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import * as yup from 'yup';

import {makeStyles} from '@mui/styles';
import Button from '@mui/material/Button';

import {Spinner} from '../../../components/spinner';
import {FormTextField} from '../../../components/text-field';
import {useCurrentAssignment} from '../../../lib/hooks';

import {UPDATE_ASSIGNMENT_NOTES} from '../queries';

const useStyles = makeStyles(theme => ({
  formAction: {
    marginTop: theme.spacing(2),
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
}));

const schema = yup.object({
  notes: yup.string().nullable(),
}).required();

export const NotesForm = ({territory}) => {
  const classes = useStyles();
  const assignment = useCurrentAssignment(territory);

  const [updateAssignmentNotes, {loading, error}] = useMutation(UPDATE_ASSIGNMENT_NOTES, {
    refetchQueries: 'active',
  });

  const {
    control,
    handleSubmit,
    setValue,
    formState: {isValid},
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'onChange',
    defaultValues: {
      notes: '',
    },
  });

  useEffect(() => {
    if (assignment?.notes) {
      // Only set a value if there is any.
      setValue('notes', assignment.notes, {shouldValidate: true});
    }
  }, [setValue, assignment]);

  const onSubmit = ({notes}) => {
    updateAssignmentNotes({
      variables: {
        id: assignment.id,
        notes,
      },
    });
  };

  if (loading || !assignment) {
    return <Spinner />;
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormTextField
        fullWidth
        multiline
        name="notes"
        label="Notizen"
        control={control}
        error={!!error}
        helperText={error ? error.message : 'Optionale Bemerkung eingeben.'}
      />

      <div className={classes.formAction}>
        <Button
          variant="contained"
          color="primary"
          type="submit"
          disabled={!isValid}
        >
          Speichern
        </Button>
      </div>
    </form>
  );
};

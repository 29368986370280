import React from 'react';
import {Link as RouterLink} from 'react-router-dom';

import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';

export const Copyright = () => (
  <Typography variant="body2" color="textSecondary" align="center">
    {'Copyright © '}
    <Link
      color="inherit"
      tabIndex={-1}
      component={RouterLink}
      to="/"
    >
      Territory Assistant
    </Link>
    {` ${new Date().getFullYear()}`}
  </Typography>
);

/**
 * Roles for colors.  Prefer using these over the palette.  It makes it easier
 * to change things.
 *
 * The only roles we need to place in here are the ones that span through the app.
 *
 * If you have a specific use-case, like a spinner color.  It makes more sense to
 * put that in the <Spinner /> component.
 */
export const color = {
  /**
   * The element colors.
   */
  action: {
    active: '#6b778c',
    hover: 'rgba(104, 142, 255, 0.08)',
  },

  /**
   * The divider color.
   */
  divider: 'rgba(145, 158, 171, 0.24)',

  /**
   * The screen background.
   */
  background: {
    default: '#171c24',
    paper: '#222b36',
  },

  /**
   * The main tinting color.
   */
  primary: {
    contrastText: '#ffffff',
    main: '#8a85ff',
  },

  /**
   * The secondary tinting color.
   */
  secondary: {
    contrastText: '#ffffff',
    main: '#8a85ff',
  },

  /**
   * The default color of text in many components.
   */
  text: {
    primary: '#ffffff',
    secondary: '#919eab',
  },
};

import {gql} from '@apollo/client';

export const SEARCH_ANYTHING = gql`
  query SearchAny($query: String!) {
    search(query: $query) {
      title
      url
      searchable {
        __typename
        ... on Publisher {
          name
        }
        ... on Territory {
          name
        }
      }
    }
  }
`;

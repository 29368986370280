import {gql} from '@apollo/client';

import {TERRITORY_FRAGMENT} from '../../components/territories';
import {ASSIGNMENT_FRAGMENT} from '../../components/assignments';

export const GET_PUBLISHER = gql`
  query GetPublisher($id: ID!) {
    publisher(id: $id, trashed: WITH) {
      id
      name
      is_suspended
      assignments(
        trashed: WITH
        orderBy: [
          { column: CREATED_AT order: DESC },
          { column: FINISHED_AT order: DESC }
        ]
      ) {
        ...AssignmentBase
        territory {
          ...TerritoryBase
        }
      }
      created_at
      suspended_at
    }
  }
  ${TERRITORY_FRAGMENT}
  ${ASSIGNMENT_FRAGMENT}
`;

import React from 'react';
import {Controller} from 'react-hook-form';

import TextField from '@mui/material/TextField';

export const FormTextField = ({
  name,
  control,
  helperText,
  ...inputProps
}) => (
  <Controller
    name={name}
    control={control}
    render={({field, fieldState: {error}}) => (
      <TextField
        {...field}
        {...inputProps}
        error={!!error}
        helperText={error?.message ?? helperText}
      />
    )}
  />
);

// Convenience export.
export {TextField};

/* eslint-disable no-underscore-dangle */
import React, {useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {useQuery} from '@apollo/client';

import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';

import {Layout} from '../../components/layout';
import {Container} from '../../components/container';
import {Toolbar} from '../../components/toolbar';
import {DataTable} from '../../components/table';
import {formatDate} from '../../lib/helper';

import {GET_ACTIVITIES} from './queries';

const linkToSubject = subject => {
  switch (subject.__typename.toLowerCase()) {
    case 'publisher':
      return `/publishers/${subject.id}`;
    case 'territory':
      return `/territories/${subject.id}`;
    default:
      return '#';
  }
};

export const Activities = () => {
  const history = useHistory();
  const [rowsPerPage, setRowsPerPage] = useState(30);
  const [currentPage, setCurrentPage] = useState(1);

  const {
    loading,
    error,
    data,
    fetchMore,
  } = useQuery(GET_ACTIVITIES, {
    variables: {first: rowsPerPage, page: currentPage},
  });

  useEffect(() => {
    fetchMore({
      variables: {first: rowsPerPage, page: currentPage},
    });
  }, [fetchMore, rowsPerPage, currentPage]);

  return (
    <Layout>
      <Container maxWidth="lg">
        <Toolbar />
        <Grid
          container
          justifyContent="center"
          spacing={3}
        >
          <Grid item xs={12}>
            <DataTable
              component={Paper}
              loading={loading}
              error={error}
              columns={[
                {key: 'created_at', label: 'Ausgeführt am'},
                {key: 'causer.id', label: 'Benutzer'},
                {key: 'description', label: 'Aktion'},
                {key: 'subject', label: 'Datensatz'},
              ]}
              renderItem={item => (
                <TableRow
                  hover
                  onClick={() => history.push(linkToSubject(item.subject))}
                  key={item.id}
                >
                  <TableCell>{formatDate(item.created_at, 'Pp')}</TableCell>
                  <TableCell>{item.causer?.name}</TableCell>
                  <TableCell>{item.description}</TableCell>
                  <TableCell>
                    {item.subject?.name}
                    <Typography
                      variant="body2"
                      color="textSecondary"
                    >
                      {item.subject?.__typename}
                    </Typography>
                  </TableCell>
                </TableRow>
              )}
              items={data?.activities.data}
              pagination={data?.activities.paginatorInfo}
              rowsPerPage={rowsPerPage}
              onPageChange={setCurrentPage}
              onRowsPerPageChange={setRowsPerPage}
            />
          </Grid>
        </Grid>
      </Container>
    </Layout>
  );
};

import React from 'react';
import {Link as RouterLink} from 'react-router-dom';
import {
  ResponsiveContainer,
  Legend,
  PieChart,
  Pie,
  Tooltip,
  Cell,
} from 'recharts';
import {gql, useQuery} from '@apollo/client';

import {useTheme, makeStyles} from '@mui/styles';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';

import {Error} from '../../../components/error';
import {Spinner} from '../../../components/spinner';

import {Title} from './title';

const GET_STATISTIC_TERRITORY_STOCK = gql`
  query GetStatisticTerritoryStock {
    statisticTerritoryStock {
      available
      unavailable
    }
  }
`;

const useStyles = makeStyles({
  titlebar: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  title: {
    flexGrow: 1,
  },
});

export const Territories = () => {
  const theme = useTheme();
  const classes = useStyles();
  const {loading, error, data} = useQuery(GET_STATISTIC_TERRITORY_STOCK);

  if (error) {
    return <Error dense error={error} />;
  }

  if (loading) {
    return <Spinner />;
  }

  const graphData = [
    {name: 'Verfügbar', value: data?.statisticTerritoryStock?.available},
    {name: 'Ausgegeben', value: data?.statisticTerritoryStock?.unavailable},
  ];

  return (
    <div>
      <Box className={classes.titlebar}>
        <Title className={classes.title}>Gebiete</Title>
        <Link component={RouterLink} to="/territories">
          Alle anzeigen
        </Link>
      </Box>

      <div style={{width: '100%', height: 300, overflow: 'hidden'}}>
        <ResponsiveContainer>
          <PieChart>
            <Tooltip
              cursor={false}
              itemStyle={{
                color: theme.palette.text.primary,
              }}
              contentStyle={{
                borderRadius: '4px',
                borderColor: theme.palette.divider,
                background: theme.palette.background.default,
              }}
            />
            <Legend />
            <Pie
              dataKey="value"
              innerRadius={60}
              outerRadius={90}
              data={graphData}
              label
            >
              <Cell
                strokeWidth={3}
                stroke={theme.palette.background.paper}
                fill={theme.palette.primary.main}
              />
              <Cell
                strokeWidth={3}
                stroke={theme.palette.background.paper}
                fill={theme.palette.error.main}
              />
            </Pie>
          </PieChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

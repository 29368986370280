import React, {useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {useQuery} from '@apollo/client';

import {makeStyles} from '@mui/styles';
import Icon from '@mui/material/Icon';
import Typography from '@mui/material/Typography';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';

import {formatDate} from '../../lib/helper';

import {DataTable} from '../table';

import {GET_PUBLISHERS} from './queries';

const useStyles = makeStyles(theme => ({
  icon: {
    fontSize: 15,
  },
  crossedText: {
    color: theme.palette.text.disabled,
    textDecoration: 'line-through',
  },
}));

export const PublisherTable = ({component, count}) => {
  const history = useHistory();
  const classes = useStyles();
  const [rowsPerPage, setRowsPerPage] = useState(count || 15);
  const [currentPage, setCurrentPage] = useState(1);

  const {
    loading,
    error,
    data,
    fetchMore,
  } = useQuery(GET_PUBLISHERS, {
    variables: {first: rowsPerPage, page: currentPage},
  });

  useEffect(() => {
    fetchMore({
      variables: {first: rowsPerPage, page: currentPage},
    });
  }, [rowsPerPage, currentPage]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <DataTable
      component={component}
      loading={loading}
      error={error}
      columns={[
        {key: 'is_suspended', label: 'Status'},
        {key: 'name', label: 'Name'},
        {key: 'created_at', label: 'Erstellt am'},
        {key: 'suspended_at', label: 'Suspendiert am'},
      ]}
      renderItem={item => (
        <TableRow
          hover
          onClick={() => history.push(`/publishers/${item.id}`)}
          key={item.id}
        >
          <TableCell
            align="center"
            padding="checkbox"
          >
            {item.is_suspended ? (
              <Icon className={classes.icon}>block</Icon>
            ) : (
              <Icon className={classes.icon}>check</Icon>
            )}
          </TableCell>
          <TableCell className={item.is_suspended ? classes.crossedText : null}>
            {item.name}
            <Typography
              variant="body2"
              color="textSecondary"
            >
              {`${item.assignments.length} Gebiet(e)`}
            </Typography>
          </TableCell>
          <TableCell>{formatDate(item.created_at)}</TableCell>
          <TableCell>{formatDate(item.suspended_at)}</TableCell>
        </TableRow>
      )}
      items={data?.publishers?.data}
      pagination={data?.publishers?.paginatorInfo}
      rowsPerPage={rowsPerPage}
      onPageChange={setCurrentPage}
      onRowsPerPageChange={setRowsPerPage}
    />
  );
};

import {createTheme} from '@mui/material/styles';

import {color} from './color';
import {shadows} from './shadows';
import {typography} from './typography';
import {components} from './components';

export default mode => createTheme({
  palette: {
    mode,
    ...color,
  },
  shape: {
    borderRadius: 16,
  },
  shadows,
  typography,
  components,
});

import React from 'react';

import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Unstable_Grid2';

import {Layout} from '../../components/layout';
import {Container} from '../../components/container';
import {Toolbar} from '../../components/toolbar';
import {UserTable} from '../../components/users';

export const Users = () => (
  <Layout>
    <Container maxWidth="lg">
      <Toolbar />
      <Grid
        container
        justifyContent="center"
        spacing={3}
      >
        <Grid item xs={12}>
          <UserTable component={Paper} />
        </Grid>
      </Grid>
    </Container>
  </Layout>
);

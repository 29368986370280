import React from 'react';
import {
  ResponsiveContainer,
  CartesianGrid,
  LineChart,
  Line,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import {gql, useQuery} from '@apollo/client';

import {useTheme, makeStyles} from '@mui/styles';
import Box from '@mui/material/Box';

import {Error} from '../../../components/error';
import {Spinner} from '../../../components/spinner';

import {Title} from './title';

const GET_STATISTIC_AVERAGE_PROCESSING_TIME = gql`
  query GetStatisticAverageProcessingTime {
    statisticAverageProcessingTime {
      processingTime
      date
    }
  }
`;

const useStyles = makeStyles({
  titlebar: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  title: {
    flexGrow: 1,
  },
});

export const Duration = () => {
  const theme = useTheme();
  const classes = useStyles();
  const {loading, error, data} = useQuery(GET_STATISTIC_AVERAGE_PROCESSING_TIME);

  if (error) {
    return <Error dense error={error} />;
  }

  if (loading) {
    return <Spinner />;
  }

  return (
    <div>
      <Box className={classes.titlebar}>
        <Title className={classes.title}>Bearbeitungszeit</Title>
      </Box>

      <div style={{width: '100%', height: 300, overflow: 'hidden'}}>
        <ResponsiveContainer>
          <LineChart data={data?.statisticAverageProcessingTime}>
            <CartesianGrid
              vertical={false}
              strokeDasharray="3 3"
              stroke={theme.palette.text.secondary}
            />
            <XAxis
              dataKey="date"
              height={50}
              tickSize={20}
              tickLine={false}
              axisLine={false}
              stroke={theme.palette.text.secondary}
            />
            <YAxis
              unit="d"
              tickSize={20}
              tickLine={false}
              axisLine={false}
              stroke={theme.palette.text.secondary}
            />
            <Tooltip
              cursor={false}
              contentStyle={{
                borderRadius: '4px',
                borderColor: theme.palette.divider,
                background: theme.palette.background.default,
              }}
            />
            <Line
              type="monotone"
              dataKey="processingTime"
              name="durchschnittliche Bearbeitungszeit in Tagen"
              strokeWidth="3"
              stroke={theme.palette.primary.main}
            />
          </LineChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

import React from 'react';
import {Link as RouterLink} from 'react-router-dom';

import {makeStyles} from '@mui/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

import {Container} from '../../components/container';

const useStyles = makeStyles({
  body: {
    display: 'flex',
    minHeight: '100vh',
    alignItems: 'center',
  },
  img: {
    width: 500,
    height: 'auto',
    maxWidth: '100%',
    maxHeight: 500,
  },
  box: {
    display: 'flex',
    justifyContent: 'center',
  },
});

export const NotFound = ({
  message = 'Die Seite wurde nicht gefunden',
  to = '/',
}) => {
  const classes = useStyles();
  return (
    <div className={classes.body}>
      <Container maxWidth="lg">
        <Typography
          variant="h3"
          align="center"
          color="textPrimary"
        >
          {message}
        </Typography>
        <Typography
          variant="subtitle1"
          align="center"
          color="textSecondary"
        >
          Entweder bin ich schuld oder du hast dich vertippt.
          Was auch immer es ist, wir bekommen das hin!
        </Typography>
        <Box className={classes.box}>
          <img
            alt="Seite nicht gefunden"
            src={`${process.env.PUBLIC_URL}/images/404.svg`}
            className={classes.img}
          />
        </Box>
        <Box className={classes.box}>
          <Button
            variant="outlined"
            color="primary"
            component={RouterLink}
            to={to}
          >
            Zurück zur Anwendung
          </Button>
        </Box>
      </Container>
    </div>
  );
};

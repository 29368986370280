import React from 'react';

import {makeStyles} from '@mui/styles';
import Grid from '@mui/material/Unstable_Grid2';
import Paper from '@mui/material/Paper';

import {AssignForm, UnassignForm, NotesForm} from '../form';

const useStyles = makeStyles(theme => ({
  formGroup: {
    marginBottom: theme.spacing(4),
    padding: theme.spacing(4),
  },
}));

export const AssignTab = ({data}) => {
  const classes = useStyles();

  return (
    <Grid
      container
      justifyContent="center"
      spacing={3}
    >
      <Grid item xs={12} md={6}>
        <Paper className={classes.formGroup}>
          {data?.territory.is_available ? (
            <AssignForm territory={data?.territory} />
          ) : (
            <UnassignForm territory={data?.territory} />
          )}
        </Paper>
      </Grid>

      <Grid item xs={12} md={6}>
        {data?.territory.is_available ? (
          /* Hide notes panel. Initial notes can be set in assignment. */
          <div />
        ) : (
          <Paper className={classes.formGroup}>
            <NotesForm territory={data?.territory} />
          </Paper>
        )}
      </Grid>
    </Grid>
  );
};

import React, {
  useRef,
  useEffect,
  useState,
} from 'react';
import {useHistory} from 'react-router-dom';
import {useLazyQuery} from '@apollo/client';

import {Input} from './input';
import {Container} from './container';

import {SEARCH_ANYTHING} from './queries';

export const Search = ({indent}) => {
  const shimInputRef = useRef();
  const overlayInputRef = useRef();
  const [query, setQuery] = useState('');
  const [open, setOpen] = useState(false);
  const history = useHistory();

  const [getSearchResults, {loading, error, data}] = useLazyQuery(SEARCH_ANYTHING, {
    fetchPolicy: 'no-cache',
  });

  // Populates the query with values from an input.
  const handleQueryChange = ({target}) => setQuery(target.value);

  // Clear the query. Will reset search.
  const handleClose = () => setQuery('');

  useEffect(() => {
    if (query) {
      // Open search drawer.
      setOpen(true);

      // And query for results.
      getSearchResults({
        variables: {query},
      });

      setTimeout(() => overlayInputRef.current?.focus(), 100);
    } else {
      // Close the drawer.
      setOpen(false);

      // Blur the searchbox. We have to use a delay here
      // to make it work because of the transitions.
      setTimeout(() => shimInputRef.current?.blur(), 100);
    }
  }, [shimInputRef, overlayInputRef, query, getSearchResults]);

  return (
    <>
      <Input
        inputRef={shimInputRef}
        value={query}
        onChange={handleQueryChange}
      />
      <Container
        indent={indent}
        loading={loading}
        error={error}
        data={data?.search}
        open={open}
        onClose={handleClose}
        onItemClick={item => history.push(item.url)}
      >
        <Input
          inputRef={overlayInputRef}
          value={query}
          onChange={handleQueryChange}
        />
      </Container>
    </>
  );
};

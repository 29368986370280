import React from 'react';
import {Link} from 'react-router-dom';
import clsx from 'clsx';

import {makeStyles} from '@mui/styles';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Button from '@mui/material/Button';

const useStyles = makeStyles(theme => ({
  fullWidth: {
    width: '100%', // fullwidth
  },
  gutter: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const getErrorMessage = ({message, extensions}) => (
  extensions?.reason ?? message
);

const ErrorAlert = ({
  variant,
  dense,
  children,
  ...rest
}) => {
  const classes = useStyles();
  const className = clsx(classes.fullWidth, !dense && classes.gutter);

  return (
    <Alert
      severity="error"
      variant={variant || 'filled'}
      className={className}
      {...rest}
    >
      {children}
    </Alert>
  );
};

export const Error = ({
  variant,
  dense,
  error,
  children,
  ...rest
}) => {
  const {graphQLErrors, networkError} = error || {
    graphQLErrors: [],
    networkError: null,
  };

  if (graphQLErrors.length) {
    return graphQLErrors.map((item, key) => (
      <ErrorAlert
        // eslint-disable-next-line react/no-array-index-key
        key={key}
        variant={variant}
        dense={dense}
        {...rest}
      >
        {getErrorMessage(item)}
      </ErrorAlert>
    ));
  }

  if (networkError) {
    return (
      <ErrorAlert
        variant={variant}
        dense={dense}
        {...rest}
      >
        {getErrorMessage(networkError)}
      </ErrorAlert>
    );
  }

  return (
    <ErrorAlert
      variant={variant}
      dense={dense}
      {...rest}
    >
      {children}
    </ErrorAlert>
  );
};

export const ErrorLink = ({
  label,
  to,
  children,
}) => (
  <Error
    action={(
      <Button
        color="inherit"
        size="small"
        component={Link}
        to={to}
      >
        {label}
      </Button>
    )}
  >
    {children}
  </Error>
);

export const ErrorTitle = AlertTitle;

import React from 'react';
import {useHistory} from 'react-router-dom';

import {makeStyles} from '@mui/styles';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';

import {formatDate} from '../../lib/helper';

const useStyles = makeStyles(theme => ({
  paperClip: {
    overflow: 'hidden',
  },
  textPadding: {
    padding: theme.spacing(4),
  },
  crossedText: {
    color: theme.palette.text.disabled,
    textDecoration: 'line-through',
  },
}));

export const AssignmentHistory = ({assignments, withPublisher, withNotes}) => {
  const history = useHistory();
  const classes = useStyles();

  const items = assignments || [];

  const renderHeader = () => (
    <TableRow>
      {withPublisher && (
        <TableCell>Verkündiger</TableCell>
      )}
      {withNotes && (
        <TableCell>Bemerkung</TableCell>
      )}
      <TableCell>Ausgegeben am</TableCell>
      <TableCell>Zuletzt bearbeitet am</TableCell>
    </TableRow>
  );

  const renderItem = item => (
    <TableRow
      hover
      onClick={() => history.push(`/publishers/${item.publisher.id}`)}
      key={item.id}
    >
      {withPublisher && (
        <TableCell
          className={item.publisher.is_suspended ? classes.crossedText : null}
        >
          {item.publisher.name}
        </TableCell>
      )}
      {withNotes && (
        <TableCell>{item.notes}</TableCell>
      )}
      <TableCell>{formatDate(item.created_at)}</TableCell>
      <TableCell>{formatDate(item.finished_at)}</TableCell>
    </TableRow>
  );

  const renderEmpty = () => {
    let columns = 2;
    if (withPublisher) columns += 1;
    if (withNotes) columns += 1;

    return (
      <TableRow>
        <TableCell align="center" colSpan={columns}>
          Kein Zuteilungsverlauf vorhanden
        </TableCell>
      </TableRow>
    );
  };

  return (
    <Paper className={classes.paperClip}>
      <TableContainer>
        <Table>
          <TableHead>
            {renderHeader()}
          </TableHead>
          <TableBody>
            {items?.length ? items.map(renderItem) : renderEmpty()}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

import {gql} from '@apollo/client';

import {TERRITORY_FRAGMENT} from '../../components/territories';
import {ASSIGNMENT_FRAGMENT} from '../../components/assignments';

export const GET_ALL_PUBLISHERS = gql`
  query GetPublishers {
    publishersAll(trashed: WITHOUT) {
      id
      name
    }
  }
`;

export const GET_TERRITORY = gql`
  query GetTerritory($id: ID!) {
    territory(id: $id) {
      ...TerritoryBase
      is_available
      assignments(
        trashed: WITH
        orderBy: [
          { column: CREATED_AT order: DESC },
          { column: FINISHED_AT order: DESC }
        ]
      ) {
        ...AssignmentBase
      }
    }
  }
  ${TERRITORY_FRAGMENT}
  ${ASSIGNMENT_FRAGMENT}
`;

export const GET_TERRITORIES_IN_STOCK = gql`
  query GetTerritoriesInStock {
    territoriesInStock {
      ...TerritoryBase
      assignments(
        trashed: WITH
        orderBy: { column: FINISHED_AT order: DESC }
      ) {
        ...AssignmentBase
      }
    }
  }
  ${TERRITORY_FRAGMENT}
  ${ASSIGNMENT_FRAGMENT}
`;

export const GET_UNTREATED_TERRITORIES = gql`
  query GetUntreatedTerritories($months: Int) {
    territoriesUntreated(months: $months) {
      ...TerritoryBase
      assignments(
        trashed: ONLY
        orderBy: { column: FINISHED_AT order: DESC }
      ) {
        ...AssignmentBase
      }
    }
  }
  ${TERRITORY_FRAGMENT}
  ${ASSIGNMENT_FRAGMENT}
`;

const ASSIGNMENT_RESPONSE = gql`
  fragment AssignmentResponse on Assignment {
    id
    territory {
      id
      is_available
    }
    finished_at
  }
`;

export const ASSIGN_TERRITORY = gql`
  mutation AssignTerritory($territory_id: ID!, $publisher_id: ID!, $created_at: DateTime, $notes: String) {
    assignTerritory(
      input: {
        territory_id: $territory_id
        publisher_id: $publisher_id
        created_at: $created_at
        notes: $notes
      }
    ) {
      ...AssignmentResponse
      publisher {
        id
        name
      }
    }
  }
  ${ASSIGNMENT_RESPONSE}
`;

export const UNASSIGN_TERRITORY = gql`
  mutation UnassignTerritory($territory_id: ID!, $finished_at: DateTime) {
    unassignTerritory(
      input: {
        territory_id: $territory_id
        finished_at: $finished_at
      }
    ) {
      ...AssignmentResponse
    }
  }
  ${ASSIGNMENT_RESPONSE}
`;

export const UPDATE_ASSIGNMENT_NOTES = gql`
  mutation UpdateAssignmentNotes($id: ID!, $notes: String) {
    updateAssignmentNotes(
      id: $id
      input: {
        notes: $notes
      }
    ) {
      ...AssignmentResponse
    }
  }
  ${ASSIGNMENT_RESPONSE}
`;

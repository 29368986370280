import {gql} from '@apollo/client';

import {ASSIGNMENT_FRAGMENT} from '../../components/assignments';

export const GET_ACTIVITIES = gql`
  query GetActivity($first: Int!, $page: Int!) {
    activities(
      first: $first,
      page: $page,
      orderBy: [
        { column: CREATED_AT order: DESC }
      ]
    ) {
      paginatorInfo {
        total
        count
        currentPage
      }
      data {
        id
        log_name
        description
        created_at
        causer {
          name
        }
        subject {
          ... on Publisher {
            id
            name
          }
          ... on Territory {
            id
            key
            name
          }
          ... on Assignment {
            ...AssignmentBase
          }
        }
        properties
      }
    }
  }
  ${ASSIGNMENT_FRAGMENT}
`;

import React from 'react';
import {Controller} from 'react-hook-form';

import TextField from '@mui/material/TextField';
import {DatePicker as MuiDatePicker} from '@mui/x-date-pickers/DatePicker';

import {formatDate} from '../../lib/helper';

export const FormDatePicker = ({
  name,
  control,
  helperText,
  ...pickerProps
}) => (
  <Controller
    name={name}
    control={control}
    render={({field: {ref, ...field}, fieldState: {error}}) => (
      <DatePicker
        {...field}
        {...pickerProps}
        helperText={error?.message ?? helperText}
      />
    )}
  />
);

export const DatePicker = ({
  minDate = new Date('1980-01-01'),
  maxDate = new Date('2100-01-01'),
  InputProps,
  value,
  onChange,
  onError,
  helperText,
  ...rest
}) => (
  <MuiDatePicker
    autoOk
    mask="__.__.____"
    minDate={minDate}
    maxDate={maxDate}
    value={value}
    onChange={onChange}
    renderInput={props => (
      <TextField
        {...props}
        {...InputProps}
        helperText={helperText}
        placeholder={formatDate(new Date())}
      />
    )}
    {...rest}
  />
);

import React, {useCallback} from 'react';
import {useQuery, useReactiveVar} from '@apollo/client';

import {makeStyles} from '@mui/styles';
import Box from '@mui/material/Box';

import {setToken, isDrawerOpenVar, setDrawerOpen} from '../../lib/cache';
import {GET_OVERDUE_ASSIGNMENTS} from '../../pages/assignments/queries';
import {GET_UNTREATED_TERRITORIES} from '../../pages/territories/queries';

import {Search} from '../search';
import {Copyright} from '../footer';

import {Toolbar} from './toolbar';
import {Drawer} from './drawer';
import {Menu} from './menu';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
}));

export const Layout = ({children}) => {
  const classes = useStyles();
  const isDrawerOpen = useReactiveVar(isDrawerOpenVar);

  const {data: assignments} = useQuery(GET_OVERDUE_ASSIGNMENTS, {
    variables: {months: 12},
  });

  const {data: territories} = useQuery(GET_UNTREATED_TERRITORIES, {
    variables: {months: 12},
  });

  const handleClearToken = useCallback(() => setToken(null), []);
  const handleOpenDrawer = useCallback(() => setDrawerOpen(true), []);
  const handleCloseDrawer = useCallback(() => setDrawerOpen(false), []);

  return (
    <div className={classes.root}>
      <Toolbar
        drawerOpen={isDrawerOpen}
        onDrawerOpenClick={handleOpenDrawer}
        onLogoutClick={handleClearToken}
      >
        <Search
          indent={isDrawerOpen}
        />
      </Toolbar>

      <Drawer
        drawerOpen={isDrawerOpen}
        onDrawerCloseClick={handleCloseDrawer}
      >
        <Menu
          overdueCount={assignments?.assignmentsOverdue.length}
          untreatedCount={territories?.territoriesUntreated.length}
        />
      </Drawer>

      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        {/* Page content */}
        {children}

        {/* Footer containing the copyright */}
        <Box p={4}>
          <Copyright />
        </Box>
      </main>
    </div>
  );
};

import React from 'react';

import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Unstable_Grid2';
// import Button from '@mui/material/Button';

import {Layout} from '../../components/layout';
import {Container} from '../../components/container';
import {Toolbar} from '../../components/toolbar';
import {TerritoryTable} from '../../components/territories';

export const Territories = () => (
  <Layout>
    <Container maxWidth="lg">
      <Toolbar>
        {/* <Button variant="contained" color="primary">Default</Button>
        <Button color="primary">Default</Button> */}
      </Toolbar>
      <Grid
        container
        justifyContent="center"
        spacing={3}
      >
        <Grid item xs={12}>
          <TerritoryTable component={Paper} />
        </Grid>
      </Grid>
    </Container>
  </Layout>
);

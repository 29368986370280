export const typography = {
  fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"',
  h1: {
    fontWeight: 600,
    fontSize: '3.5rem',
  },
  h2: {
    fontWeight: 600,
    fontSize: '3rem',
  },
  h3: {
    fontWeight: 600,
    fontSize: '2.25rem',
  },
  h4: {
    fontWeight: 600,
    fontSize: '2rem',
  },
  h5: {
    fontWeight: 600,
    fontSize: '1.5rem',
  },
  h6: {
    fontWeight: 600,
    fontSize: '1.125rem',
  },
  overline: {
    fontWeight: 600,
  },
  button: {
    fontWeight: 600,
  },
};

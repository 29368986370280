import {gql} from '@apollo/client';

export const ASSIGNMENT_FRAGMENT = gql`
  fragment AssignmentBase on Assignment {
    id
    notes
    publisher(trashed: WITH) {
      id
      name
      is_suspended
    }
    territory {
      id
      key
      name
    }
    created_at
    finished_at
  }
`;

export const GET_ASSIGNMENTS = gql`
  query GetAssignments($first: Int!, $page: Int!) {
    assignments(
      first: $first,
      page: $page,
      trashed: WITH
      orderBy: [
        { column: CREATED_AT order: DESC },
        { column: FINISHED_AT order: DESC }
      ]
    ) {
      paginatorInfo {
        total
        count
        currentPage
      }
      data {
        ...AssignmentBase
      }
    }
  }
  ${ASSIGNMENT_FRAGMENT}
`;

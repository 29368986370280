import React from 'react';
import {Link as RouterLink} from 'react-router-dom';

import MuiBreadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import Icon from '@mui/material/Icon';

export const Breadcrumbs = ({breadcrumbs}) => (
  <MuiBreadcrumbs
    aria-label="breadcrumb"
    separator={(
      <Icon fontSize="small">navigate_next</Icon>
    )}
  >
    {breadcrumbs.map(({path, name}, index) => {
      const last = index === breadcrumbs.length - 1;
      return last ? (
        <Typography
          variant="subtitle2"
          color="inherit"
          key={path}
        >
          {name}
        </Typography>
      ) : (
        <Link
          variant="subtitle2"
          color="textPrimary"
          component={RouterLink}
          to={path}
          key={path}
        >
          {name}
        </Link>
      );
    })}
  </MuiBreadcrumbs>
);

import React, {useContext} from 'react';

import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';

import {BreadcrumbContext} from '../../navigation/breadcrumbs';

import {Breadcrumbs} from './breadcrumbs';

export const Toolbar = ({children, title}) => {
  const breadcrumbs = useContext(BreadcrumbContext);

  // Extract title from breadcrumbs
  // eslint-disable-next-line react/destructuring-assignment
  const pageTitle = title || breadcrumbs[breadcrumbs.length - 1].name;

  return (
    <Grid
      container
      spacing={3}
      justifyContent="space-between"
    >
      <Grid item>
        <Typography
          component="h5"
          variant="h5"
          color="inherit"
        >
          {pageTitle}
        </Typography>
        <Breadcrumbs breadcrumbs={breadcrumbs} />
      </Grid>
      <Grid item>
        {children}
      </Grid>
    </Grid>
  );
};

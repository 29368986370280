import React from 'react';
import {useHistory} from 'react-router-dom';

import {makeStyles} from '@mui/styles';
import Grid from '@mui/material/Unstable_Grid2';
import Paper from '@mui/material/Paper';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';

import {DataTable} from '../../../components/table';

import {formatDate, formatDateDistance} from '../../../lib/helper';

const useStyles = makeStyles(theme => ({
  crossedText: {
    color: theme.palette.text.disabled,
    textDecoration: 'line-through',
  },
}));

export const PublishersTab = ({data}) => {
  const classes = useStyles();
  const history = useHistory();

  return (
    <Grid
      container
      justifyContent="center"
      spacing={3}
    >
      <Grid item xs={12}>
        <DataTable
          component={Paper}
          columns={[
            {key: 'name', label: 'Verkündiger'},
            {key: 'notes', label: 'Bemerkung'},
            {key: 'created_at', label: 'Ausgegeben am'},
            {key: 'finished_at', label: 'Zuletzt bearbeitet am'},
            {key: 'editing_time', label: 'Bearbeitungszeit'},
          ]}
          renderItem={item => (
            <TableRow
              hover
              onClick={() => history.push(`/publishers/${item.publisher.id}`)}
              key={item.id}
            >
              <TableCell
                className={item.publisher.is_suspended ? classes.crossedText : null}
              >
                {item.publisher.name}
              </TableCell>
              <TableCell>{item.notes}</TableCell>
              <TableCell>{formatDate(item.created_at)}</TableCell>
              <TableCell>{formatDate(item.finished_at)}</TableCell>
              <TableCell>
                {formatDateDistance(item.created_at, item.finished_at)}
              </TableCell>
            </TableRow>
          )}
          items={data?.territory.assignments}
        />
      </Grid>
    </Grid>
  );
};

import React, {useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {useQuery} from '@apollo/client';

import {makeStyles} from '@mui/styles';
import Typography from '@mui/material/Typography';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';

import {formatDate} from '../../lib/helper';

import {DataTable} from '../table';

import {GET_ASSIGNMENTS} from './queries';

const useStyles = makeStyles(theme => ({
  crossedText: {
    color: theme.palette.text.disabled,
    textDecoration: 'line-through',
  },
}));

export const AssignmentTable = ({component, count}) => {
  const history = useHistory();
  const classes = useStyles();
  const [rowsPerPage, setRowsPerPage] = useState(count || 15);
  const [currentPage, setCurrentPage] = useState(1);

  const {
    loading,
    error,
    data,
    fetchMore,
  } = useQuery(GET_ASSIGNMENTS, {
    variables: {first: rowsPerPage, page: currentPage},
  });

  useEffect(() => {
    fetchMore({
      variables: {first: rowsPerPage, page: currentPage},
    });
  }, [rowsPerPage, currentPage]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <DataTable
      component={component}
      loading={loading}
      error={error}
      columns={[
        {key: 'publisher.name', label: 'Verkündiger'},
        {key: 'territory.key', label: 'Gebiet'},
        {key: 'notes', label: 'Notiz'},
        {key: 'created_at', label: 'Ausgegeben am', processor: formatDate},
        {key: 'finished_at', label: 'Zuletzt bearbeitet am', processor: formatDate},
      ]}
      renderItem={item => (
        <TableRow
          hover
          onClick={() => history.push(`/publishers/${item.publisher.id}`)}
          key={item.id}
        >
          <TableCell className={item.publisher.is_suspended ? classes.crossedText : null}>
            {item.publisher.name}
          </TableCell>
          <TableCell>
            {item.territory.key}
            <Typography
              variant="body2"
              color="textSecondary"
            >
              {item.territory.name}
            </Typography>
          </TableCell>
          <TableCell>{item.notes}</TableCell>
          <TableCell>{formatDate(item.created_at)}</TableCell>
          <TableCell>{formatDate(item.finished_at)}</TableCell>
        </TableRow>
      )}
      items={data?.assignments?.data}
      pagination={data?.assignments?.paginatorInfo}
      rowsPerPage={rowsPerPage}
      onPageChange={setCurrentPage}
      onRowsPerPageChange={setRowsPerPage}
    />
  );
};

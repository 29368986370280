import React from 'react';
import {Controller} from 'react-hook-form';

import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import MuiSelect from '@mui/material/Select';

export const FormSelect = ({
  name,
  control,
  helperText,
  ...selectProps
}) => (
  <Controller
    name={name}
    control={control}
    render={({field: {ref, ...field}, fieldState: {error}}) => (
      <Select
        {...field}
        {...selectProps}
        name={name}
        error={!!error}
        helperText={error?.message ?? helperText}
      />
    )}
  />
);

export const Select = ({
  name,
  label,
  helperText,
  options,
  value,
  onChange,
  ...rest
}) => {
  const handleChange = event => {
    onChange(event.target.value);
  };

  return (
    <FormControl {...rest}>
      <InputLabel htmlFor={name}>
        {label}
      </InputLabel>
      <MuiSelect
        native
        label={label}
        inputProps={{name}}
        value={value}
        onChange={handleChange}
      >
        {options.map(option => (
          <option key={option.value} value={option.value}>{option.label}</option>
        ))}
      </MuiSelect>
      <FormHelperText>{helperText}</FormHelperText>
    </FormControl>
  );
};

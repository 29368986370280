import React from 'react';
import {Route, Redirect} from 'react-router-dom';
import {useReactiveVar} from '@apollo/client';

import {isAuthenticatedVar} from '../lib/cache';

const BreadcrumbRoute = ({routes, render, ...rest}) => (
  <Route
    {...rest}
    render={props => {
      const {match} = props;
      const breadcrumbs = routes
        // Get all routes that contain the current one.
        .filter(({path}) => match.path.includes(path))
        // Swap out any dynamic routes with their param values.
        .map(({path, ...other}) => {
          let temp = path;

          if (Object.keys(match.params).length) {
            // Reducer callback function.
            const replaceParam = (segment, param) => (
              segment.replace(`:${param}`, match.params[param])
            );

            // Replace parameters.
            temp = Object.keys(match.params).reduce(replaceParam, path);
          }

          return {
            path: temp,
            ...other,
          };
        })
        .reverse();

      return render({breadcrumbs, ...props});
    }}
  />
);

const AuthRoute = ({renderProtected, renderGuest, ...rest}) => {
  const isAuthenticated = useReactiveVar(isAuthenticatedVar);
  return (
    <BreadcrumbRoute
      {...rest}
      render={props => (
        isAuthenticated ? (
          renderProtected(props)
        ) : (
          renderGuest(props)
        )
      )}
    />
  );
};

// A wrapper for <Route> that redirects to the login
// screen if you're not yet authenticated.
const PrivateRoute = ({children, ...rest}) => (
  <AuthRoute
    {...rest}
    renderProtected={({breadcrumbs}) => children(breadcrumbs)}
    renderGuest={({location}) => (
      <Redirect
        to={{
          pathname: '/login',
          state: {from: location},
        }}
      />
    )}
  />
);

const GuestRoute = ({children, ...rest}) => (
  <AuthRoute
    {...rest}
    renderProtected={({location}) => (
      <Redirect
        to={{
          pathname: '/',
          state: {from: location},
        }}
      />
    )}
    renderGuest={({breadcrumbs}) => children(breadcrumbs)}
  />
);

export {Route, PrivateRoute, GuestRoute};

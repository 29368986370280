import React from 'react';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';

import {Error} from '../error';
import {Spinner} from '../spinner';

export const DataTable = ({
  component,
  columns,
  renderHeader,
  renderItem,
  renderEmpty,
  items,
  loading,
  error,
  pagination,
  onRowClick,
  onPageChange,
  onRowsPerPageChange,
  rowsPerPage,
  rowsPerPageOptions,
}) => {
  const handlePageChange = (event, newPage) => {
    onPageChange(newPage + 1);
  };

  const handleRowsPerPageChange = event => {
    onRowsPerPageChange(parseInt(event.target.value, 10));
    onPageChange(1);
  };

  const canClickRow = !!onRowClick;

  const handleRowClick = item => (
    onRowClick?.(item)
  );

  const saveRenderHeader = renderHeader || (cols => (
    <TableRow>
      {cols.map(({key, label}) => (
        <TableCell key={key}>{label}</TableCell>
      ))}
    </TableRow>
  ));

  const saveRenderItem = renderItem || (item => (
    <TableRow
      hover={canClickRow}
      onClick={() => handleRowClick(item)}
      key={item.id}
    >
      {columns.map(({key, processor}) => (
        <TableCell key={key}>{processor ? processor(item[key]) : item[key]}</TableCell>
      ))}
    </TableRow>
  ));

  const saveRenderEmpty = renderEmpty || (() => (
    <TableRow>
      <TableCell align="center" colSpan={columns.length}>
        Keine Daten verfügbar
      </TableCell>
    </TableRow>
  ));

  if (loading) {
    return <Spinner />;
  }

  return (
    <>
      {error && (
        <Error error={error} />
      )}
      <TableContainer component={component || 'div'}>
        <Table>
          <TableHead>
            {saveRenderHeader(columns)}
          </TableHead>
          <TableBody>
            {items?.length ? items.map(saveRenderItem) : saveRenderEmpty()}
          </TableBody>
        </Table>
      </TableContainer>
      {pagination && (
        <TablePagination
          rowsPerPageOptions={rowsPerPageOptions || [5, 15, 30]}
          component="div"
          count={pagination.total}
          page={pagination.currentPage - 1}
          rowsPerPage={rowsPerPage}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleRowsPerPageChange}
        />
      )}
    </>
  );
};

import {InMemoryCache, makeVar} from '@apollo/client';

function persistKey(key, value) {
  if (value) {
    localStorage.setItem(key, value);
  } else {
    localStorage.removeItem(key, value);
  }
}

function mergePaginatedFieldPolicy(keyArgs: bool = false) {
  return {
    keyArgs,
    merge(existing: any[], incoming: any[], {variables}): any[] {
      const merged = existing ? existing.slice(0) : [];

      // Insert the incoming elements in the right places, according to args.
      // We have to calculate our offset by page and size.
      // We assume the page size is the count of
      // the first elements returned.
      const offset = variables.first * (variables.page - 1);
      const end = offset + Math.min(variables.first, incoming.length);

      for (let i = offset; i < end; i += 1) {
        merged[i] = incoming[i - offset];
      }

      return merged;
    },
    read(existing: any[], {variables}): any[] {
      // We have to calculate our offset by page and size.
      const offset = variables.first * (variables.page - 1);

      // If we read the field before any data has been written to the
      // cache, this function will return undefined, which correctly
      // indicates that the field is missing.
      const page = existing && existing.slice(
        offset,
        offset + variables.first,
      );

      // If we ask for a page outside the bounds of the existing array,
      // page.length will be 0, and we should return undefined instead of
      // the empty array.
      if (page && page.length > 0) {
        return page;
      }

      return undefined;
    },
  };
}

export const isAuthenticatedVar = makeVar(!!localStorage.getItem('token'));

export const setToken = newValue => {
  persistKey('token', newValue);
  isAuthenticatedVar(!!newValue);
};

export const isDrawerOpenVar = makeVar(!!localStorage.getItem('drawer'));

export const setDrawerOpen = newValue => {
  persistKey('drawer', newValue);
  isDrawerOpenVar(!!newValue);
};

export const cache = new InMemoryCache({
  // @link https://www.apollographql.com/docs/react/caching/cache-field-behavior/
  typePolicies: {
    AssignmentPaginator: {
      fields: {
        data: mergePaginatedFieldPolicy(['trashed', 'orderBy']),
      },
    },
    PublisherPaginator: {
      fields: {
        data: mergePaginatedFieldPolicy(['trashed']),
      },
    },
    TerritoryPaginator: {
      fields: {
        data: mergePaginatedFieldPolicy(),
      },
    },
    UserPaginator: {
      fields: {
        data: mergePaginatedFieldPolicy(),
      },
    },
  },
});

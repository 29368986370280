import React from 'react';

import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Unstable_Grid2';

import {Layout} from '../../components/layout';
import {Container} from '../../components/container';
import {Toolbar} from '../../components/toolbar';
import {PublisherTable} from '../../components/publisher';

export const Publishers = () => (
  <Layout>
    <Container maxWidth="lg">
      <Toolbar />
      <Grid
        container
        justifyContent="center"
        spacing={3}
      >
        <Grid item xs={12}>
          <PublisherTable component={Paper} />
        </Grid>
      </Grid>
    </Container>
  </Layout>
);

import React from 'react';
import {useHistory, useParams} from 'react-router-dom';
import {useQuery} from '@apollo/client';

import {makeStyles} from '@mui/styles';
import Grid from '@mui/material/Unstable_Grid2';
import Paper from '@mui/material/Paper';
import Alert from '@mui/material/Alert';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';

import {Layout} from '../../components/layout';
import {Container} from '../../components/container';
import {Toolbar} from '../../components/toolbar';
import {DataTable} from '../../components/table';

import {NotFound} from '../error';

import {formatDate, formatDateDistance} from '../../lib/helper';

import {GET_PUBLISHER} from './queries';

const useStyles = makeStyles(theme => ({
  crossedText: {
    color: theme.palette.text.disabled,
    textDecoration: 'line-through',
  },
}));

export const Profile = () => {
  const history = useHistory();
  const classes = useStyles();
  const {id} = useParams();

  const {loading, error, data} = useQuery(GET_PUBLISHER, {
    variables: {id},
  });

  if (data && !data.publisher) {
    return (
      <NotFound
        message="Der Verkündiger wurde nicht gefunden"
        to="/publishers"
      />
    );
  }

  return (
    <Layout>
      <Container maxWidth="lg">
        <Toolbar title={data?.publisher?.name} />
        <Grid
          container
          justifyContent="center"
          spacing={3}
        >
          {data?.publisher?.is_suspended && (
            <Grid item xs={12}>
              <Alert severity="warning" variant="filled">
                {`Dieser Verkündiger wurde am ${formatDate(data.publisher.suspended_at)} suspendiert. Ihm können keine neuen Gebiete zugeteilt werden.`}
              </Alert>
            </Grid>
          )}

          {data?.publisher && (
            <Grid item xs={12}>
              <DataTable
                component={Paper}
                loading={loading}
                error={error}
                columns={[
                  {key: 'key', label: 'Gebiets-Nr.'},
                  {key: 'name', label: 'Ort'},
                  {key: 'notes', label: 'Bemerkung'},
                  {key: 'created_at', label: 'Ausgegeben am'},
                  {key: 'finished_at', label: 'Zuletzt bearbeitet am'},
                  {key: 'editing_time', label: 'Bearbeitungszeit'},
                ]}
                renderItem={item => (
                  <TableRow
                    hover
                    onClick={() => history.push(`/territories/${item.territory.id}`)}
                    key={item.id}
                  >
                    <TableCell className={item.finished_at ? classes.crossedText : null}>
                      {item.territory.key}
                    </TableCell>
                    <TableCell className={item.finished_at ? classes.crossedText : null}>
                      {item.territory.name}
                    </TableCell>
                    <TableCell>{item.notes}</TableCell>
                    <TableCell>{formatDate(item.created_at)}</TableCell>
                    <TableCell>{formatDate(item.finished_at)}</TableCell>
                    <TableCell>{formatDateDistance(item.created_at, item.finished_at)}</TableCell>
                  </TableRow>
                )}
                items={data?.publisher.assignments}
              />
            </Grid>
          )}
        </Grid>
      </Container>
    </Layout>
  );
};

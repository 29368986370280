import React from 'react';
import {NavLink} from 'react-router-dom';

import {makeStyles} from '@mui/styles';
import Badge from '@mui/material/Badge';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import Icon from '@mui/material/Icon';

const useStyles = makeStyles(theme => ({
  icon: {
    color: 'inherit',
  },
  header: {
    zIndex: 5,
    paddingTop: theme.spacing(2),
    color: theme.palette.text.primary,
    background: theme.palette.background.paper,
    textTransform: 'uppercase',
  },
  button: {
    color: 'rgb(145, 158, 171)',
  },
  selected: {
    color: theme.palette.primary.main,
  },
}));

const MenuIcon = ({children, ...rest}) => {
  const classes = useStyles();
  return (
    <ListItemIcon
      className={classes.icon}
      {...rest}
    >
      {children}
    </ListItemIcon>
  );
};

const MenuHeader = ({children, ...rest}) => {
  const classes = useStyles();
  return (
    <ListSubheader
      className={classes.header}
      {...rest}
    >
      {children}
    </ListSubheader>
  );
};

const MenuItemBase = ({children, ...rest}) => {
  const classes = useStyles();
  return (
    <ListItem
      button
      component={NavLink}
      className={classes.button}
      activeClassName={classes.selected}
      {...rest}
    >
      {children}
    </ListItem>
  );
};

const MenuItem = ({
  icon,
  label,
  badgeContent,
  ...rest
}) => (
  <MenuItemBase {...rest}>
    <MenuIcon>
      <Badge
        badgeContent={badgeContent}
        color="error"
        max={999}
      >
        <Icon>{icon}</Icon>
      </Badge>
    </MenuIcon>
    <ListItemText
      primary={label}
      primaryTypographyProps={{variant: 'subtitle2'}}
    />
  </MenuItemBase>
);

export const Menu = ({overdueCount, untreatedCount}) => (
  <List>
    <MenuHeader inset>Zuteilungen</MenuHeader>

    <MenuItem
      to="/assignments/export"
      icon="cloud_download"
      label="Exportieren"
    />
    <MenuItem
      badgeContent={overdueCount}
      to="/assignments/overdue"
      icon="update"
      label="Überfällig"
    />

    <MenuHeader inset>Gebiete</MenuHeader>

    <MenuItem
      to="/territories/stock"
      icon="inbox"
      label="Bestand"
    />
    <MenuItem
      badgeContent={untreatedCount}
      to="/territories/untreated"
      icon="update"
      label="Überfällig"
    />

    <MenuHeader inset>Verwaltung</MenuHeader>

    <MenuItem
      exact
      to="/assignments"
      icon="link"
      label="Zuteilungen"
    />
    <MenuItem
      exact
      to="/territories"
      icon="layers"
      label="Gebiete"
    />
    <MenuItem
      to="/publishers"
      icon="people"
      label="Verkündiger"
    />

    <MenuHeader inset>Administration</MenuHeader>

    <MenuItem
      exact
      to="/"
      icon="dashboard"
      label="Dashboard"
    />
    <MenuItem
      to="/log"
      icon="track_changes"
      label="Aktivitäten"
    />
    <MenuItem
      to="/users"
      icon="vpn_key"
      label="Benutzer"
    />
  </List>
);

import React from 'react';
import clsx from 'clsx';

import {makeStyles} from '@mui/styles';
import AppBar from '@mui/material/AppBar';
import MuiToolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Icon from '@mui/material/Icon';

const useStyles = makeStyles(theme => ({
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  appBar: {
    backgroundColor: theme.palette.background.paper,
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: 240,
    width: 'calc(100% - 240px)',
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
}));

export const Toolbar = ({
  children,
  drawerOpen,
  onDrawerOpenClick,
  onLogoutClick,
}) => {
  const classes = useStyles();

  return (
    <AppBar
      enableColorOnDark
      position="absolute"
      className={clsx(classes.appBar, drawerOpen && classes.appBarShift)}
    >
      <MuiToolbar className={classes.toolbar}>

        <IconButton
          edge="start"
          color="inherit"
          tabIndex={-1}
          onClick={onDrawerOpenClick}
          className={clsx(classes.menuButton, drawerOpen && classes.menuButtonHidden)}
          size="large"
        >
          <Icon>menu</Icon>
        </IconButton>

        {/* Custom content */}
        {children}

        <IconButton
          color="inherit"
          onClick={onLogoutClick}
          size="large"
        >
          <Icon>logout</Icon>
        </IconButton>
      </MuiToolbar>
    </AppBar>
  );
};

import React from 'react';

import {makeStyles} from '@mui/styles';
import CircularProgress from '@mui/material/CircularProgress';

const useStyles = makeStyles(theme => ({
  spinner: {
    display: 'flex',
    justifyContent: 'center',
    padding: theme.spacing(4),
  },
}));

export const Spinner = ({color}) => {
  const classes = useStyles();
  return (
    <div className={classes.spinner}>
      <CircularProgress color={color || 'primary'} />
    </div>
  );
};

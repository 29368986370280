import {gql} from '@apollo/client';

import {TERRITORY_FRAGMENT} from '../../components/territories';
import {ASSIGNMENT_FRAGMENT} from '../../components/assignments';

export const GET_ALL_TERRITORIES = gql`
  query GetTerritories {
    territoriesAll {
      ...TerritoryBase
    }
  }
  ${TERRITORY_FRAGMENT}
`;

export const GET_OVERDUE_ASSIGNMENTS = gql`
  query GetOverdueAssignments($months: Int) {
    assignmentsOverdue(
      months: $months
      orderBy: { column: CREATED_AT, order: ASC }
    ) {
      ...AssignmentBase
      territory {
        ...TerritoryBase
      }
    }
  }
  ${TERRITORY_FRAGMENT}
  ${ASSIGNMENT_FRAGMENT}
`;

export const EXPORT_ASSIGNMENT_RECORD = gql`
  mutation ExportAssignmentRecord(
    $territories: [String],
    $orderBy: [MutationExportAssignmentRecordOrderByOrderByClause!]
    $from: DateTime,
    $to: DateTime
  ) {
    exportAssignmentRecord(
      territories: $territories
      orderBy: $orderBy
      from: $from
      to: $to
    )
  }
`;

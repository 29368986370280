import React from 'react';

import Icon from '@mui/material/Icon';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

const getIconForType = ({__typename}) => {
  switch (__typename) {
    case 'Publisher':
      return 'people';
    case 'Territory':
      return 'layers';
    default:
      return 'help';
  }
};

const getSecondaryForType = ({__typename, name}) => {
  switch (__typename) {
    // case 'Publisher':
    //   return null;
    case 'Territory':
      return name;
    default:
      return null;
  }
};

export const Results = ({
  items,
  onItemClick,
}) => {
  const canClickItem = !!onItemClick;

  const handleItemClick = item => (
    onItemClick?.(item)
  );

  const renderItem = item => (
    <ListItem
      button={canClickItem}
      onClick={() => handleItemClick(item)}
      key={item.url}
    >
      <ListItemIcon>
        <Icon>{getIconForType(item.searchable)}</Icon>
      </ListItemIcon>
      <ListItemText
        primary={item.title}
        secondary={getSecondaryForType(item.searchable)}
      />
    </ListItem>
  );

  const renderEmpty = () => (
    <ListItem>
      <ListItemText
        inset
        primary="Keine Ergebnisse gefunden"
      />
    </ListItem>
  );

  return (
    <List
      component="nav"
      dense
    >
      {items?.length ? items.map(renderItem) : renderEmpty()}
    </List>
  );
};

import {gql} from '@apollo/client';

export const TERRITORY_FRAGMENT = gql`
  fragment TerritoryBase on Territory {
    id
    key
    name
  }
`;

export const GET_TERRITORIES = gql`
  query GetTerritories($first: Int!, $page: Int!) {
    territories(first: $first, page: $page) {
      paginatorInfo {
        total
        count
        currentPage
      }
      data {
        ...TerritoryBase
        is_available
        created_at
        updated_at
      }
    }
  }
  ${TERRITORY_FRAGMENT}
`;

import React from 'react';
import {useMutation} from '@apollo/client';
import {useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import * as yup from 'yup';

import {makeStyles} from '@mui/styles';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Icon from '@mui/material/Icon';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';

import {setToken} from '../../lib/cache';

import {FormTextField} from '../../components/text-field';
import {Error} from '../../components/error';
import {Spinner} from '../../components/spinner';
import {Copyright} from '../../components/footer';

import {LOGIN_USER} from './queries';

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const schema = yup.object({
  email: yup.string()
    .required('E-Mail muss ausgefüllt sein.')
    .email('Keine gültige E-Mail.'),
  password: yup.string()
    .required('Passwort muss ausgefüllt sein.'),
}).required();

export const Login = () => {
  const classes = useStyles();

  const [loginUser, {loading, error}] = useMutation(LOGIN_USER, {
    onCompleted({login}) {
      setToken(login);
    },
  });

  const {
    control,
    handleSubmit,
    formState: {isValid},
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'onChange',
    defaultValues: {
      email: '',
      password: '',
    },
  });

  const onSubmit = ({email, password}) => {
    loginUser({
      variables: {email, password},
    });
  };

  return (
    <Container component="main" maxWidth="xs">
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <Icon>lock-outlined</Icon>
        </Avatar>
        <Typography component="h1" variant="h3">
          Anmelden
        </Typography>
        {error && (
          <Error error={error} />
        )}
        {loading ? (
          <Spinner />
        ) : (
          <form
            className={classes.form}
            onSubmit={handleSubmit(onSubmit)}
          >

            <FormTextField
              margin="normal"
              autoFocus
              fullWidth
              autoComplete="email"
              name="email"
              label="E-Mail"
              control={control}
            />

            <FormTextField
              margin="normal"
              fullWidth
              autoComplete="current-password"
              name="password"
              label="Passwort"
              type="password"
              control={control}
            />

            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              disabled={!isValid}
              className={classes.submit}
            >
              Anmelden
            </Button>
          </form>
        )}
      </div>
      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
  );
};
